class AccordianItem {
  constructor(item, container) {
    this.item = item;
    this.container = container;
    this.content = item.querySelector('.accordian-content');
    this.isOpen = false;
    this.toggleButton = item.querySelector('button');

    const me = this;
    this.toggleButton.addEventListener('click', () => {
      if (!me.isOpen) {
        me.container.closeAccordianItems();
        me.open();
        return;
      }

      if (me.container.allowClose) {
        me.close();
      }
    });
  }

  open() {
    // hide closed icon
    if (this.container.hideToggle) {
      this.toggleButton.classList.add('d-none');
    }
    // show content
    this.content.classList.remove('d-none');
    this.isOpen = true;
  }

  close() {
    // show closed icon
    this.toggleButton.classList.remove('d-none');
    // hide content
    this.content.classList.add('d-none');
    this.isOpen = false;
  }
}

export default AccordianItem;
