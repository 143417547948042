class Main {
  constructor(moduleList) {
    const modules = moduleList.reduce((obj, x) => {
      const reduced = { ...obj, [x.name()]: x };
      return reduced;
    }, Object.create(null));

    this.instantiateModules = ({ selector = 'body', refresh = false }) => {
      const root = document.querySelector(selector);

      root.querySelectorAll('[data-module]').forEach((el) => {
        const { module, ...props } = el.dataset;

        // only run refreshable modules when refresh is true
        if (modules[module] && (!refresh || modules[module].refreshable)) {
          if (props.init !== false) {
            const instance = new modules[module](props, el);
            instance.init();
          }
        }
      });
    };
  }

  init() {
    this.instantiateModules({});
  }

  refresh() {
    this.instantiateModules({ refresh: true });
  }
}

export default Main;
