class Carousel {
  static name() { return 'Carousel'; }

  constructor(props, el) {
    this.props = props;
    this.el = el;
    this.$el = $(el);

    this.objects = {};
  }

  init() {
    console.info('~~~ Carousel ~~~');

    this.$el.owlCarousel({
      center: true,
      items: 2,
      loop: true,
      margin: 10,
      autoplay: true,
      autoplayTimeout: 3000,
      autoplayHoverPause: true,
      pagination: false,
      navigation: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 5,
        },
      },
    });
  }
}

export default Carousel;
